/* Resets and base styles */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body, html {
  height: 100%;
  font-family: Arial, sans-serif;
}

/* Application container */
.app-container {
  display: flex;
  min-height: 100vh;
  background-color: #2E2F38;
  color: #fff;
}

/* Sidebar styles */
.sidebar {
  position: fixed;
  width: 250px;
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: #000000;
  padding: 20px;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.5);
}

/* Chat container styles */
.chat-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 250px; /* Same as sidebar width */
  height: 100vh; /* Full viewport height */
  overflow: hidden;
  align-items: center; /* Center align the chat container */
}

/* Messages area styles */
.messages {
  overflow-y: auto;
  padding: 10px;
  margin-bottom: 10px;
  flex-grow: 1;
  width: 100%; /* Adjust width as needed */
  padding-left: 15%;
  padding-right: 15%;
}

/* Individual message styles */
.message {
  color: white;
  padding: 10px;
  margin-bottom: 10px;
  word-wrap: break-word;
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.message-header {
  display: flex;
  align-items: center;
  gap: 20px;
}

.message-icon {
  width: 30px;
  height: 30px;
  margin-right: 0px; /* Aligns with the gap in message-header */
}

.message-label {
  font-weight: bold;
  margin-bottom: 5px; /* Space between label and text */
}

.message-text {
  margin-left: 50px;
  white-space: pre-line; /* Collapses multiple whitespaces and preserves line breaks */
  text-align: left;
  font-size: 1rem;
  line-height: 1.1; /* Further reduced line height for compactness */

  /* Styling for lists */
  ul, ol {
    display: block;
    margin-block-end: 0em!important;
    margin-block-start: 0em!important;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 10px;
    margin: 0 0; /* Reduced top and bottom margin for lists */
    padding-left: 20px; /* Indentation for lists */
    margin-top: 0px;
    margin-bottom: 0px;
    padding-top: 0px;
    padding-bottom: 0px;
}

  ul li, ol li {
    margin-bottom: 0.0em; /* Further reduced space between list items */
    white-space: normal;
  }
  ul ul, ol ol, ul ol, ol ul {
    margin-left: 70px;
  }
  
  /* Second level of nesting */
  ul ul ul, ol ol ol, ul ul ol, ol ol ul, ul ol ul, ol ul ol, ul ol ol, ol ul ul {
    margin-left: 90px;
  }
}



/* User message styles */
.message.user {
  align-self: flex-end;
}

/* Response message styles */
.message.response {
  align-self: flex-start;
}

/* Message input area styles */
.message-input {
  display: flex;
  align-items: center;
  padding: 10px;
  border-color: #4D4D4D;
  border-width: 1px;
  border-style: solid;
  border-radius: 8px;
  width: 70%;
  margin-bottom: 20px;
  left: 250px; /* Same as sidebar width */
}

/* Input and button styles */
input[type="text"] {
  flex-grow: 1;
  padding: 10px;
  margin-right: 10px;
  border: none;
  color: #fff;
  background-color: #2E2F38;
  border-radius: 4px;
  width: calc(100% - 120px); /* Adjust width to avoid overflow */
}

button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  background-color: #016ec5;
  color: white;
  cursor: pointer;
}

button:hover {
  background-color: #45a049;
}

/* Scrollbar styles for messages area */
.messages::-webkit-scrollbar {
  width: 5px;
}

.messages::-webkit-scrollbar-thumb {
  background: #888;
}

.messages::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.message-label {
  font-weight: bold;
}
.sidebar-header {
  display: flex;
  align-items: center;
  gap: 20px;
}
.new-chat-text {
  /* New chat */
font-style: normal;
font-weight: 600;
font-size: 14px;
line-height: 17px;
/* or 121% */

color: #FFFFFF;
}
.edit-chat-icon {
  margin-left: auto
}
.send-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  padding: 10px 10px 7px 10px;
  align-items: center;
  justify-content: center;
  border-color: #4D4D4D;
  border-width: 1px;
  border-style: solid;
  border-radius: 8px;
}

.send-button img {
  width: 12px;
  height: 12px;
  
}
.sub-level {
  padding-left: 20px;
  padding-top: 20px;
  display: flex;
  width: 100%;
  gap: 10px;
}
/* Bank.ai */
.bankai-text {
font-style: normal;
font-weight: 600;
font-size: 20px;
line-height: 30px;
/* identical to box height, or 150% */

color: #FFFFFF;

}
.tier-tag {
  background-color: #A4CAEE;
  border-radius: 4px;
  padding-left: 6px;
  padding-right: 6px;
  margin-top: auto;
  margin-bottom: auto;
}
.tag-text{
  color: #3F4354;
/* Regular 14 */
font-style: normal;
font-weight: 600;
font-size: 12px;
line-height: 22px;
/* identical to box height, or 157% */
text-align: center;

/* Secondary Color/Secondary 2 */
color: #3F4354;


/* Inside auto layout */
flex: none;
order: 0;
flex-grow: 0;

}
input:focus {
  outline: none;
}
.date-side-text {
  /* Today */

font-style: normal;
font-weight: 600;
font-size: 12px;
line-height: 17px;
/* or 142% */

color: #898989;


}
.date-side {
  margin-top: 20px;
  overflow-y: auto;
  height: calc(100% - 200px); 
}
.side-chat-name {
  /* New chat */

font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 17px;
/* or 121% */

color: #FFFFFF;


}
.upgrade-side {
  display: flex;
  gap: 16px;
  margin-top: 12px;
}
.upgrade-tite {
  /* Upgrade plan */
font-style: normal;
font-weight: 600;
font-size: 14px;
line-height: 17px;
/* or 121% */

color: #FFFFFF;


}
.upgrade-desc {
  /* Get Bank.ai Premium, and more */
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 17px;
/* or 142% */

color: #B2B2B2;
}
.user-tite {
  /* Upgrade plan */
font-style: normal;
font-weight: 600;
font-size: 14px;
line-height: 17px;
/* or 121% */

color: #FFFFFF;
margin-top: auto;
margin-bottom: auto;

}
.lower-side {
  margin-top: auto;
}
.profile-circle {
  background-color: blue;
  width: 30px;
  height: 30px;
  align-items: center;
  display: flex;
  align-self: center;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  float: left;
  border-radius: 30px !important;
}
.user-profile {
  display: flex;
  float: left;
    gap: 16px;
    margin-top: 20px;
}
.circle-initials {
  height: 30px;
  width: 30px;
  line-height: 30px;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  align-self: center;
}
.main-logo {
  height: 20px;
  width: auto;
  display: inline;
}
.date-side-item {
  padding: 10px;
}
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f5f5f5;
}

.login-container form {
  display: flex;
  flex-direction: column;
  width: 300px;
  padding: 20px;
  border-radius: 5px;
  background: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.login-container input {
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
background-color: #fff !important;
width: 100% !important;
color: #000;
}

.login-container button {
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
.sub-level {
  display: flex;
  justify-content: space-between; /* Aligns items to the start and end of the container */
  align-items: center;
  padding: 10px;
}

.sub-level-content {
  display: flex;
  align-items: center;
}

.logout-button {
  padding: 5px 10px;
  background-color: #2E2F38;
  color: #b50000;
  border-color: #b50000;
  border: solid;
  border-width: 1px;
  border-radius: 4px;
  cursor: pointer;
}
.logout-button:hover {
  background-color: #b5000026;
}
form > input {
background-color: #fff;
width: 100%;
}

.sub-level-content {
gap: 10px;
}
.feedback-box {
  margin-top: 10px;
  padding-left: 50px;
  width: 100%;
}
.feedback-area {
  width: 100%;
}
.feedback-input {
  width: 100%;
  padding: 6px;
  height: 60px;
  resize: none;
}
.feedback-box textarea {
  width: 100%;
  height: 60px; /* Adjust as needed */
  margin-bottom: 10px;
  border-color: #4D4D4D;
  border-width: 1px;
  border-style: solid;
  border-radius: 8px;
  background: none;
  font-family: Arial, sans-serif;
  padding: 10px;
  color: #fff;

}
textarea:focus { 
  outline: none !important;
  box-shadow: 0;
}

.feedback-box button {
  margin-right: 5px;
}
.response-icons {
  display: flex;
  gap: 5px;
  margin-left: 50px;
}
.feedback-buttons {
  display: flex;
  float: right;
  gap: 10px;
}

.feedback-cancel {
  background: none;
  color: #8f8f8f;
  border-color: #4D4D4D;
  border-width: 1px;
  border-style: solid;
}
.feedback-cancel:hover {
  background-color: #1D1E20;
  border-color: #1D1E20;
}
.feedback-submit {
  background-color: #016ec5;
  
}
.feedback-submit:hover {
  background-color: #015a9c;
}
.app-container-login {
  background-color: #000000;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;
  box-sizing: border-box;
  color: #fff;
  position: relative;
}

.content-area {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.top-left-logo {
  width: 120px;
  position: absolute;
  top: 30px;
  left: 30px;
}

.initial-view {
  background-color: #1D1E20;
  padding: 30px;
  border-radius: 8px;
  text-align: center;
  width: 320px;
}

.login-button, .waitlist-button {
  background-color: #016eC4;
  border: none;
  padding: 10px 20px;
  color: white;
  cursor: pointer;
  margin-top: 20px;
  border-radius: 5px;
  width: 100%;
}

.login-button:hover, .waitlist-button:hover {
  background-color: #015a9c;
}

.footer-logo {
  width: 80px;
  margin-top: 20px;
}

.footer-links {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  text-decoration: underline;
  font-size: 14px;
  color: #B2B2B2;
}

.separator {
  margin: 0 10px;
}

.login-footer {
  position: absolute;
  bottom: 20px;
  text-align: center;
  width: 100%;
  align-items: center;
}
.back-arrow {
  float: left;
  margin-left: -10px;
  margin-top: -10px;
}
.login-email {
  width: 100% !important;
  background-color:#1D1E20 !important;
  border-radius: 6px !important;
  border-style: solid !important;
  border-color: #4D4D4D !important;
  height: 40px !important;
  border-width: 1px !important;
  padding-left: 10px !important;
  color: #fff;
}
.login-pass {
  width: 100% !important;
  background-color:#1D1E20 !important;
  border-radius: 6px !important;
  border-width: 1px !important;
  border-style: solid !important;
  border-color: #4D4D4D !important;
  height: 40px !important;
  padding-left: 10px !important;
  color: #fff;
}
.login-label {
  height: 20px;
  padding-right: 10px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #FFFFFF;
  margin-top: 20px;
  margin-bottom: 4px;
  float: left;
}
.login-text {
  font-size: 26px;
  line-height: 38px;
  margin-bottom: 0px;
}
.initial-login-button {
  width: 100%;
  background-color: #1D1E20;
  border-color: #4D4D4D;
  border-width: 1px;
  margin-top: 20px;
  border-radius: 5px;
  border-style: solid;
  width: 100%;
}
.initial-login-button:hover {
  background-color: #2E2F38;
}
.initial-center-logo {
  margin-bottom: 20px;
  height: 70px;
  width: 70px;
}